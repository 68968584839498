.card1 {
    width: 110px; /* Adjust card width as needed */
    height: 150px; /* Adjust card height as needed */
    background-color: #fff;
    border-radius: 4px;
    padding: 20px;
    margin: 10px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

 
  